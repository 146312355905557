// Plugins
window.HAUtil = require('@/components/util.js');
window.HASearchBarDropdown = require('@/components/search-bar-dropdown.js');
window.HAMenu = require('@/components/menu.js');

// Layout base js
window.KTApp = require('@/layout/app.js');
// window.KTLayoutAside = require('@/layout/aside.js');
// window.KTLayoutExplore = require('@/layout/explore.js');
// window.KTLayoutSearch = require('@/layout/search.js');
// window.KTLayoutToolbar = require('@/layout/toolbar.js');
