"use strict";

// Class definition
const HASearchBarDropdown = function(element) {
    ////////////////////////////
    // ** Private Variables  ** //
    ////////////////////////////
    const the = this;

    if (typeof element === "undefined" || element === null) {
        return;
    }

    const _init = function() {
        the.element = element;
        the.toggle = element.querySelector('.ha-dropdown-toggle');
        the.content = element.querySelector('.ha-dropdown-content');

        // Event Handlers
        _initEvents();
    }

    const _initEvents = function() {
        the.toggle.addEventListener('click', function(e) {
            e.stopPropagation();
            _toggleDropdown();
        });

        document.addEventListener('click', function(e) {
            if (!the.element.contains(e.target)) {
                _hideDropdown();
            }
        });
    }

    const _toggleDropdown = function() {
        the.toggle.classList.toggle('ha-open');
        
        if (the.toggle.classList.contains('ha-open')) {
            _updatePosition();
        }
    }

    const _hideDropdown = function() {
        the.toggle.classList.remove('ha-open');
    }

    const _updatePosition = function() {
        const rect = the.element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const contentHeight = the.content.offsetHeight;
        const spaceBelow = windowHeight - rect.bottom;
        
        the.content.classList.remove('dropdown-above', 'dropdown-below');
        
        if (spaceBelow < (contentHeight + 32)) {
            the.content.classList.add('dropdown-above');
        } else {
            the.content.classList.add('dropdown-below');
        }
    }

    // Initialize
    _init();
};

// Create instances
HASearchBarDropdown.createInstances = function(selector = '.ha-dropdown') {
    const elements = document.querySelectorAll(selector);
    
    if (elements && elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
            new HASearchBarDropdown(elements[i]);
        }
    }
}

// Initialize
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => HASearchBarDropdown.createInstances());
} else {
    HASearchBarDropdown.createInstances();
}