"use strict";

const HAApp = function() {
    // const initPageLoader =  function() {
    //     HAUtil.removeClass(document.body, 'page-loading');
    // }

    const initSelect2 = function() {
        const elements = Array.from(document.querySelectorAll('[data-control="select2"], [data-ha-select2="true"]'));

        elements.forEach(element => {
            const options = {
                dir: document.body.getAttribute('direction'),
                minimumResultsForSearch: element.getAttribute('data-hide-search') === 'true' ? Infinity : undefined
            };

            $(element).select2(options);

            if (element.hasAttribute('data-icon')) {
                const iconClass = element.getAttribute('data-icon');
                const select2Container = $(element).data('select2').$container;
                // // const placeholder = select2Container.find('.select2-selection__placeholder');
                select2Container.addClass(`icon ${iconClass}`);
            }
        });
    }

    const initCountUp = function() {
        const elements = [].slice.call(document.querySelectorAll('[data-ha-countup="true"]:not(.counted)'));

        elements.map(function (element) {
            if (HAUtil.isInViewport(element) && HAUtil.visible(element) ) {
                const options = {};

                const value = element.getAttribute('data-ha-countup-value');
                value = parseFloat(value.replace(/,/g,""));

                if (element.hasAttribute('data-ha-countup-start-val')) {
                    options.startVal = parseFloat(element.getAttribute('data-ha-countup-start-val'));
                }

                if (element.hasAttribute('data-ha-countup-duration')) {
                    options.duration = parseInt(element.getAttribute('data-ha-countup-duration'));
                }

                if (element.hasAttribute('data-ha-countup-decimal-places')) {
                    options.decimalPlaces = parseInt(element.getAttribute('data-ha-countup-decimal-places'));
                }

                if (element.hasAttribute('data-ha-countup-prefix')) {
                    options.prefix = element.getAttribute('data-ha-countup-prefix');
                }

                if (element.hasAttribute('data-ha-countup-suffix')) {
                    options.suffix = element.getAttribute('data-ha-countup-suffix');
                }

                const count = new countUp.CountUp(element, value, options);

                count.start();
                
                element.classList.add('counted');
            }                
        });
    }

    return {
        init: function() {
            this.initSelect2();
            this.initCountUp();
        },

        initSelect2: function() {
            initSelect2();
        },

        initCountUp: function() {
            initCountUp();
        }
    };
}();

// On document ready
HAUtil.onDOMContentLoaded(function() {
    HAApp.init();
});

// On window load
// window.addEventListener("load", function() {
// 	HAApp.initPageLoader();
// });

// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = HAApp;
}