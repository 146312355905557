"use strict";

// Class definition
const HAMenu = function() {
    ////////////////////////////
    // ** Private Variables  ** //
    ////////////////////////////
    const the = this;

    const _init = function() {
        the.burgerMenu = document.querySelector('.burger-menu');
        the.menuWrapper = document.querySelector('.menu-wrapper');

        // Event Handlers
        _initEvents();
    }

    const _initEvents = function() {
        the.burgerMenu.addEventListener('click', function() {
            _toggleMenu();
        });
    }

    const _toggleMenu = function() {
        the.burgerMenu.classList.toggle('active');
        the.menuWrapper.classList.toggle('active');
    }

    // Initialize
    _init();
};

// Initialize
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => new HAMenu());
} else {
    new HAMenu();
}